@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-Light-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-Light-Pro_Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-LightItalic-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-LightItalic-Pro_Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-Book-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-Book-Pro_Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-BookItalic-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-BookItalic-Pro_Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-Medium-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-Medium-Pro_Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-MediumItalic-Pro_Web.woff2")
      format("woff2"),
    url("./fonts/woff/WhitneySSm-MediumItalic-Pro_Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-Semibold-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-Semibold-Pro_Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-SemiboldItalic-Pro_Web.woff2")
      format("woff2"),
    url("./fonts/woff/WhitneySSm-SemiboldItalic-Pro_Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-Bold-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-Bold-Pro_Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-BoldItalic-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-BoldItalic-Pro_Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-Black-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-Black-Pro_Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./fonts/woff2/WhitneySSm-BlackItalic-Pro_Web.woff2") format("woff2"),
    url("./fonts/woff/WhitneySSm-BlackItalic-Pro_Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

:root {
  --primary: theme("colors.blue.700");
}

body {
  background-color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Whitney", sans-serif;
}

.pixelated {
  image-rendering: pixelated;
}
